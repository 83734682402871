import React from 'react';

import { Collapse, Drawer } from 'antd';
import cn from 'classnames';
import { inject, observer } from 'mobx-react'
import Image from 'next/image'
import Link from 'next/link'
import Router from 'next/router';

import Icon from '@components/Icon';
import {
  LOGIN_BEFORE_PATHS
} from '@constants/index';
import { Button } from '@element/index'
import I18n from '@utils/i18n';
import p from '@utils/Transfrom';

import { DrawerKeyEum } from './const'
import { getH5Menu } from './utils'

import styles from './index.module.less';
type MenuDrawerProps = {
  isOnlyLogin?: boolean;
  keys?: [(keyof typeof DrawerKeyEum)];
  guideist?: { image: string }[];
  setVisible: (visible: boolean) => void;
  visible: boolean;
  userStore?: {
    isLogin: boolean;
  }
}
const { Panel } = Collapse;
const MenuDrawer: React.FC<MenuDrawerProps> = ({
  guideist, setVisible, isOnlyLogin, keys,
  visible, userStore: { isLogin }
}) => {
  const onClose = () => {
    setVisible(false)
  };
  const data = getH5Menu(guideist)
  const filterData = keys?.length ? (
    data.filter(item => keys.includes(item?.dataBuried as (keyof typeof DrawerKeyEum)))
  ) : data;
  console.log(data, 'datadata')
  const renderPanelitem = (child) => {
    const { dataBuried, linkText, link = '', imgUrl, className } = child
    return (
      <Button className={cn(styles.localeBtn, styles.panelitem, className)} data-buried={dataBuried} key={linkText}>
        {imgUrl && <div className={cn(styles.imgUrlBox, child.imageBgStyle)}>
          <Image src={imgUrl} layout="fixed" width={p(30)} height={p(30)} alt='' />
        </div>}
        <span>
          <Link href={link}><a className={styles.btnText}>{linkText}</a></Link>
        </span>
      </Button>
    )
  }
  const renderCollapseMenu = (i) => {
    return (
      <Collapse
        key={i.title}
        expandIconPosition='right'
        expandIcon={({ isActive }) => <Icon
          style={{ fontSize: '12px', color: '#696F7F' }}
          name={(isActive ? 'arrowUp' : 'arrowDown')} />}
        className={cn(styles.collapse, i.className)}
      >
        {
          (isLogin || i.noLoginShow) && (
            <Panel header={(
              <Button className={styles.localeBtn}>
                <div className={cn(styles.imgUrlBox, i.imageBgStyle)}>
                  <Image src={i.imgUrl} layout="fixed" width={p(30)} height={p(30)} alt=''
                  />
                </div>
                <span className={cn(styles.btnText, styles.marginLeft)}>
                  {i.title}
                </span>
              </Button>
            )}
              key="1"
            >
              <div className={styles.panellist}>
                {
                  i?.childrens?.map((child, index) => {
                    if (Object.prototype.hasOwnProperty.call(child, '$$typeof')) return child
                    if (child.childrens && child.childrens.length > 0) return renderCollapseMenu(child)
                    return renderPanelitem(child)
                  })
                }
              </div>
            </Panel>
          )
        }
      </Collapse>
    )
  }
  return (
    <Drawer width={300} closable={false} placement="right" onClose={onClose} visible={visible}>
      <div className={styles.drawerHeader}>
        <Button className={styles.closeBtn} onClick={onClose} key='close'>
          <Image src={require('@images/icon/close.webp')} layout="fixed" width={p(48)} height={p(48)} alt='' />
        </Button>
      </div>
      <div className={styles.btnList}>
        {
          !isLogin &&
          <>
            <Button
              className={cn(styles.loginBtn, { [styles.onlyLogin]: isOnlyLogin })}
              type='ghost'
              onClick={() => Router.push(LOGIN_BEFORE_PATHS.LOGIN)}
              data-buried="login"
              key='login'
            >
              <span className='Medium'>{I18n.t('login')}</span>
            </Button>
            {!isOnlyLogin && <Button
              className={styles.regBtn}
              type='primary'
              onClick={() => Router.push(LOGIN_BEFORE_PATHS.REGISTER)}
              data-buried="register"
              key='register'
            >
              <span className='Medium'>{I18n.t('register')}</span>
            </Button>}
          </>
        }
        {
          filterData.map((i, index) => {
            if (i?.childrens?.length > 0) {
              return renderCollapseMenu(i)
            }
            return renderPanelitem(i)
          })
        }
      </div>
    </Drawer>
  )
}
export default inject((rootStore: any) => ({
  userStore: rootStore.store.userStore
}))(observer(MenuDrawer))
