import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'

import Icon from '@components/Icon'
import helper from '@utils/helper'

import styles from './index.module.css'

export const GuideList = ({ data }) => {
  console.log(data, 'datadata')
  return (
    <div className={styles.contain}>
      {
        data.map((i, index) => (
          i.image
            ? <Link href={i[`path_${helper.getLocaleKey()}`] || ''} key={index}>
              <a title={i[`herf_title_${helper.getLocaleKey()}`]} data-buried="guide-list" target={'_blank'}>
                <div key='index' tabIndex={index} className={styles.containItem}
                >
                  <Image
                    src={i.image}
                    layout="fixed"
                    width={28}
                    height={28}
                    alt='kikitrade'
                  />
                  <span className={cn(styles.title, 'Medium')}>{i[`title_${helper.getLocaleKey()}`]}</span>
                  <Icon name='arrowRight' className={styles.arrowRight} />
                </div>
              </a>
            </Link>
            : <div key={index} className={styles.containItemNull}></div>
        ))
      }
    </div>
  )
}
