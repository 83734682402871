import { SupportAPI } from '@api/support';
import {
  AIRDROP_PATHS,
  FINANCIAL,
  INVITATION,
  MARKET_PATHS,
  THEME,
  TRADE_PATHS,
  USER_PATHS,
  WALLET_PATHS
} from '@constants/index';
import { store } from '@store/index';
import helper from '@utils/helper';

const WALLET_MENU = [
  {
    iconName: 'accountAllAssets',
    name: 'accountAllAssets',
    key: 'accountAllAssets',
    href: WALLET_PATHS.ACCOUNT_OVERVIEW
  },
  {
    iconName: 'transferAccount',
    name: 'transferAccount',
    key: 'transferAccount',
    href: WALLET_PATHS.TRADE_OVERVIEW
  },
  {
    iconName: 'financeAccount',
    name: 'financeAccount',
    key: 'financeAccount',
    href: WALLET_PATHS.FINANCE_OVERVIEW
  },
  {
    iconName: 'C2c',
    name: 'c2cAccount',
    key: 'c2cAccount',
    href: WALLET_PATHS.C2C_ACCOUNT,
    iconStyle: { fontSize: '17px', marginRight: '12px' }
  },
  {
    iconName: 'depositWithdrawal',
    name: 'deposit_withdrawal',
    key: 'deposit_withdrawal',
    href: WALLET_PATHS.COIN_LIST
  },
  {
    iconName: 'historyRecords',
    name: 'historyRecords',
    key: 'historyRecords',
    href: '',
    children: [
      {
        iconName: '',
        name: 'tradeHistory',
        href: WALLET_PATHS.HISTORY_REWARD
      },
      {
        iconName: '',
        name: 'financeHistory',
        href: WALLET_PATHS.HISTORY_FINANCE_REGULAR_INVESTMENT
      }
    ]
  }
];

const MARKET_MENU = [
  {
    iconName: 'marketStation',
    name: 'quotes',
    href: MARKET_PATHS.MARKET,
    hasNextIcon: true,
    dataBuried: 'web_market_submenutype_select_markets',
    tips: '8ty873AO'
  },
  {
    iconName: 'findIcon',
    name: 'nEMVdvEh',
    href: MARKET_PATHS.FIND,
    hasNextIcon: true,
    dataBuried: 'web_market_submenutype_select_discover',
    tips: 'pkDbjWBm'
  }
];

const TRANSACTION_MENU = [
  {
    iconName: 'trade',
    name: 'market_spot',
    href: `${TRADE_PATHS.TRADE}/BTC_USDT`,
    hasNextIcon: true
  },
  {
    iconName: 'regularInvestment',
    name: 'regularInvestment',
    href: FINANCIAL.AIP,
    hasNextIcon: true
  }
]

const FINACIAL_MENU = [
  // {
  //   iconName: 'flexRegular',
  //   name: 'flexibleAndFixed',
  //   href: FINANCIAL.REGULAR_AND_FLEXIBLE,
  //   hasNextIcon: true
  // },
  {
    iconName: 'regularInvestment',
    name: 'regularInvestment',
    href: FINANCIAL.AIP,
    hasNextIcon: true
  }
];

const ACTIVITY_MENU = [
  {
    iconName: 'invite_friends',
    name: 'hKcw74Rd',
    href: INVITATION,
    hasNextIcon: true
  },
  {
    iconName: 'airdropPlan',
    name: 'Z9gqKqVO',
    href: AIRDROP_PATHS.ME,
    hasNextIcon: true
  }
]

const ORDER_MENU = [
  {
    // 现货订单
    iconName: 'spotOrder',
    name: 'nav_spot_order', // localeKey
    href: ''
  },
  {
    // C2C订单
    iconName: 'c2cOrder',
    name: 'nav_C2C_order',
    href: ''
  },
  {
    // 理财订单
    iconName: 'yieldOrder',
    name: 'nav_yield_order',
    href: ''
  },
  {
    // 买币历史
    iconName: 'buyCoinHistory',
    name: 'nav_buy_coin_history',
    href: ''
  }
];

const PROFILE_MENU = [
  {
    // 安全相关
    activekey: '2',
    iconName: 'securitycenter',
    name: 'layout_safety_relate',
    href: USER_PATHS.SAFETY
  },
  {
    // 身份认证
    activekey: '5',
    iconName: 'idAuth',
    name: 'auth',
    href: USER_PATHS.KYC
  },
  {
    // 安全相关
    activekey: '1',
    iconName: 'risk',
    name: 'risk_test',
    href: USER_PATHS.RISK
  },
  {
    // 其他设置
    activekey: '3',
    iconName: 'othersettings',
    name: 'layout_othersetting',
    href: USER_PATHS.OTHER_SETTING
  },
  {
    // 我的交易
    activekey: '4',
    iconName: 'mytransaction',
    name: 'layout_mybusiness',
    href: USER_PATHS.TRADE
  },
  {
    // 邀请好友
    activekey: '6',
    iconName: 'invitefriends',
    name: 'home_invite_friends',
    dividerTop: true,
    href: INVITATION
  },
  {
    // 联系客服
    activekey: '7',
    iconName: 'contactService',
    name: 'user_contact_us',
    customerLink: 'https://kikitrade.zendesk.com/hc/en-hk/requests/new'
  }
];

export const jumpPageMap = {
  7: () => {
    // 进行区分判断当前系统应该使用中文客服（现有）和英文客服表单（新增）
    if (helper.isNormalContactUs(store.userStore.socialUserInfo.countryCode)) {
      SupportAPI.getNamedSupportUrl()
        .then((contactCustomerUrl) => {
          window.open(contactCustomerUrl);
        })
        .catch((e) => {
          console.log('error:', e);
        });
    } else {
      window.open('https://kikitrade.zendesk.com/hc/en-hk/requests/new');
    }
  }
};

const BACKGROUND_IMG = {
  [THEME.HIGHLIGHT]: require('@images/logo_white_bg.webp'),
  [THEME.DARK]: require('@images/logo_dark_bg.webp')
};

const SEARCH_IMG = {
  [THEME.HIGHLIGHT]: require('@images/search.webp'),
  [THEME.DARK]: require('@images/darkSearch.webp')
};

const MENU_IMG = {
  [THEME.HIGHLIGHT]: require('@images/icon/menu.webp'),
  [THEME.DARK]: require('@images/darkMenu.webp')
};

export {
  ACTIVITY_MENU,
  BACKGROUND_IMG,
  FINACIAL_MENU,
  MARKET_MENU,
  MENU_IMG,
  ORDER_MENU,
  PROFILE_MENU,
  SEARCH_IMG,
  TRANSACTION_MENU,
  WALLET_MENU
};
