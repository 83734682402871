import React from 'react';
import Image from 'next/image'
import { Drawer } from 'antd'
import { Button, InputSearch } from '@element/index'
import { PopoverContent } from '@components/CoinSearch/Component'
import useInputChange from '@components/CoinSearch/InputHooks'
import { inject, observer } from 'mobx-react'
import cn from 'classnames'
import styles from './index.module.css'
import closeIcon from '@images/icon/close.webp'

const SearchDrawer = ({
  onClose,
  visible,
  marketStore: { hotSearchList, marketSymbols, marketCoins },
  topSearchType,
  itemClick
}) => {
  const { onInputChange, popType, filterSearchList, setPopType, text, settext } = useInputChange()
  const handleClose = () => {
    setPopType('DEFAULT')
    settext('')
    onClose()
  }
  return (
    <Drawer
      // width={300}
      className={cn(styles.drawer, {
        [styles.drawerWidth]: topSearchType === '3'
      })}
      closable={false}
      placement="right"
      onClose={handleClose}
      visible={visible}
    >
        <Button className={cn(styles.closeBtn)} onClick={handleClose}>
          <Image
            src={closeIcon}
            layout="fixed"
            width={24}
            height={24}
            alt=''
          />
      </Button>
      <div className={styles.inputSearchLine}>
        <InputSearch
          className={styles.inputSearch}
          onChange={(e) => {
            onInputChange(e, marketSymbols, marketCoins)
          }}
          value={text}
        />
      </div>
      <PopoverContent
        // className={cn(styles.DrawerSearch, searchClassName)}
          className={cn(styles.DrawerSearch, {
            [styles.DrawerSearchWidth]: topSearchType === '3'
          })}
          type={popType}
          // {...props}
          hotSearchList={hotSearchList}
          filterSearchList={filterSearchList}
          inputVal={text}
          itemClick={handleClose}
      />
    </Drawer>
  )
}

export default inject(rootStore => {
  return {
    marketStore: rootStore.store.marketStore
  }
})(observer(SearchDrawer))
