import cn from 'classnames';

import Icon from '@components/Icon';
import {
  AIRDROP_PATHS,
  COMMUNITY_PATHS, FINANCIAL,
  INVITATION,
  LOGIN_BEFORE_PATHS,
  MARKET_PATHS, NEWS_PATHS,
  PROJECTS_PATHS,
  TRADE_PATHS,
  WALLET_PATHS
} from '@constants/index';
import helper from '@utils/helper'
import I18n, { languageOptions } from '@utils/i18n';

import { DrawerKeyEum } from './const'
import { H5MeunItemTypes } from './index.type'

import styles from './index.module.less';

const changeLanguage = (item: { label?: string; value: any; key?: string; }) => helper.languageSwitch(item.value);

export const getH5Menu = (guideist): H5MeunItemTypes[] => {
  return [{
    dataBuried: DrawerKeyEum.deposit1,
    imgUrl: require('@images/icon/deposit.webp'),
    link: '/deposit',
    imageBgStyle: styles.imageBgStyle,
    linkText: I18n.t('deposit1'),
    btnStyle: styles.depositBtn
  },
  {
    dataBuried: DrawerKeyEum.community_top,
    imageBgStyle: styles.imageBgStyle,
    imgUrl: require('@images/icon/community.webp'),
    link: COMMUNITY_PATHS.COMMUNITY,
    linkText: I18n.t('community_top')
  }, {
    imageBgStyle: styles.imageBgStyle,
    imgUrl: require('@images/nava_spot.webp'),
    noLoginShow: true,
    title: I18n.t('66nM140s'),
    childrens: [{
      link: MARKET_PATHS.MARKET,
      linkText: I18n.t('quotes'),
      dataBuried: 'web_market_submenutype_select_markets'
    }, {
      link: MARKET_PATHS.FIND,
      linkText: I18n.t('nEMVdvEh'),
      dataBuried: 'web_market_submenutype_select_discover'
    }]
  },
  {
    imageBgStyle: styles.imageBgStyle,
    dataBuried: DrawerKeyEum.trade,
    imgUrl: require('@images/icon/transactionIcon.webp'),
    noLoginShow: true,
    title: I18n.t('asset_exchange'),
    childrens: [
      {
        link: `${TRADE_PATHS.TRADE}/BTC_USDT`,
        linkText: I18n.t('market_spot'),
        dataBuried: 'nava-spot'
      },
      {
        link: FINANCIAL.AIP,
        linkText: I18n.t('regularInvestment'),
        dataBuried: 'nava-spot'
      }
    ]
  },
  // {
  //   imageBgStyle: styles.imageBgStyle,
  //   dataBuried: DrawerKeyEum.financial,
  //   imgUrl: require('@images/icon/financial.webp'),
  //   noLoginShow: true,
  //   title: I18n.t('savings'),
  //   childrens: [
  //     // {
  //     //   link: FINANCIAL.REGULAR_AND_FLEXIBLE,
  //     //   linkText: I18n.t('flexibleAndFixed'),
  //     //   dataBuried: 'financial'
  //     // },
  //     {
  //       link: FINANCIAL.AIP,
  //       linkText: I18n.t('regularInvestment'),
  //       dataBuried: 'nava-spot'
  //     }
  //   ]
  // },
  {
    imageBgStyle: styles.imageBgStyle,
    dataBuried: DrawerKeyEum.activity,
    imgUrl: require('@images/home_invite_friends.webp'),

    noLoginShow: true,
    title: I18n.t('WaEmnBbe'),
    childrens: [{
      link: INVITATION,
      linkText: I18n.t('hKcw74Rd'),
      dataBuried: 'financial'
    }, {
      link: AIRDROP_PATHS.ME,
      // todo: 扫描翻译后，替换为key值
      linkText: I18n.t('Z9gqKqVO'),
      dataBuried: 'nava-spot'
    }]
  },
  {
    imageBgStyle: styles.imageBgStyle,
    dataBuried: DrawerKeyEum.projects,
    imgUrl: require('@images/projects_icon.webp'),
    link: PROJECTS_PATHS.PROJECTS,
    linkText: 'Web 3.0'
  },
  {
    imageBgStyle: styles.imageBgStyle,
    dataBuried: DrawerKeyEum.news,
    imgUrl: require('@images/news.webp'),
    link: NEWS_PATHS.INDEX,
    linkText: I18n.t('news')
  }, {
    imageBgStyle: styles.imageBgStyle,
    noLoginShow: false,
    dataBuried: DrawerKeyEum.wallet1,
    title: I18n.t('wallet1'),
    imgUrl: require('@images/wallet1.webp'),
    childrens: [{
      link: WALLET_PATHS.ACCOUNT_OVERVIEW,
      linkText: I18n.t('accountAllAssets'),
      imgUrl: require('@images/icon/accountAllAssets.webp')
    }, {
      link: WALLET_PATHS.TRADE_OVERVIEW,
      linkText: I18n.t('transferAccount'),
      imgUrl: require('@images/icon/transferAccount.webp')
    }, {
      link: WALLET_PATHS.FINANCE_OVERVIEW,
      linkText: I18n.t('financeAccount'),
      imgUrl: require('@images/icon/financeAccount.webp')
    }, {
      link: WALLET_PATHS.C2C_ACCOUNT,
      linkText: I18n.t('c2cAccount'),
      imgUrl: require('@images/c2c.webp'),
      className: styles.bottomBorder
    }, {
      link: WALLET_PATHS.COIN_LIST,
      linkText: I18n.t('deposit_withdrawal'),
      imgUrl: require('@images/icon/depositWithdrawal.webp')
    }, {
      link: WALLET_PATHS.HISTORY_REWARD,
      title: I18n.t('historyRecords'),
      imgUrl: require('@images/icon/historyRecords.webp'),
      className: styles.secondCollapse,
      childrens: [{
        link: WALLET_PATHS.HISTORY_REWARD,
        linkText: I18n.t('tradeHistory')
      }, {
        link: WALLET_PATHS.HISTORY_FINANCE_REGULAR_INVESTMENT,
        linkText: I18n.t('financeHistory')
      }]
    }]
  }, {
    imageBgStyle: styles.imageBgStyle,
    dataBuried: DrawerKeyEum.more,

    noLoginShow: true,
    title: I18n.t('market_more'),
    imgUrl: require('@images/guide.webp'),
    className: styles.bottomBorder,
    childrens: guideist?.filter((i: { image: any; }) => i.image).map((i: { [x: string]: any; }) => ({
      link: i[`path_${helper.getLocaleKey()}`],
      linkText: i[`title_${helper.getLocaleKey()}`]
    }))
  }, {
    imageBgStyle: styles.imageBgStyle,
    noLoginShow: true,
    dataBuried: DrawerKeyEum.language,
    title: I18n.t('language'),
    imgUrl: require('@images/icon/locale.webp'),
    childrens: languageOptions.map((item, index) => (<div onClick={
      () => { changeLanguage(item) }
    } key={index} className={cn(styles.panelitem, {
      [styles.language]: helper.getLocaleKey() === item.key
    })}>
      <div className={cn(styles.title, styles.marginLeft)}>{item.label}</div>
      <Icon name='select' className={styles.selectIcon} />
    </div>))
  }, {
    imageBgStyle: styles.imageBgStyle,
    dataBuried: DrawerKeyEum.download,
    imgUrl: require('@images/download_mobile.webp'),
    link: LOGIN_BEFORE_PATHS.DOWNLOAD,
    linkText: I18n.t('download')
  }]
}
