
import React from 'react';

import cn from 'classnames';
// import Image from 'next/image';
import Link from 'next/link';

import { THEME } from '@constants/index';
import Logo from '@images/svgIcon/logo.svg'

// import { BACKGROUND_IMG } from '../../const';
import styles from './index.module.less';

export const KikiLogo = (props: { theme?: Theme, width?: string, height?: string}) => {
  const { theme = THEME.HIGHLIGHT, width, height } = props;
  return (
    <Link href='/' passHref>
        <a className={styles.a} data-buried="logo">
          <Logo width={width} height={height} className={cn(styles.logo, {
            [styles.darkLogo]: theme === 'dark'
          })} />
        </a>
    </Link>
  )
}
